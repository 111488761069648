function loadScript() {
	$(".quiz__qna-list-item").on("click", function () {
		var parent = $(this);
		if (parent.hasClass("active")) {
			parent.removeClass("active");
		} else {
			$(".quiz__qna-list-item").removeClass("active");
			parent.addClass("active");
		}
	});
}
loadScript();
